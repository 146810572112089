.product-list-main-section {
  background-color: #fff;
  padding: 0.4rem 1rem;
  width: 100%;
  aspect-ratio: 5/3;
}

.product-list-main-section h3 {
  font-size: 1.2em;
  font-weight: 700;
  height: 1.4em;
}

.product-list-main-section .product-list-main-desc {
  font-size: 0.95em;
  width: 70%;
  height: 1.4em;
}

.product-list-main-section .product-list-main-list-wrapper {
  padding-top: 0.8rem;
}

.product-list-item-aspect-10-17 {
  aspect-ratio: 10/19.5 !important;
}

/* 최대한 기존 코드를 건들지 않는 범위 내에서 수정한다 */
.product-list-main-section-pc {
  background-color: #fff;
  padding: 1rem 1rem;
  width: 100%;
}

.product-list-main-section-pc h3 {
  font-size: 1.2em;
  font-weight: 700;
  height: 1.4em;
}

.product-list-main-section-pc .product-list-main-desc {
  font-size: 0.95em;
  height: 1.4em;
}

.product-list-main-section-pc .product-list-main-list-wrapper {
  padding-top: 1.3rem;
}


.light-green-row {
  background-color: #90ee90; /* light green color */
}

.light-pink-row {
  background-color: rgb(228, 180, 236); /* light green color */
}

.light-orange-row {
  background-color: rgb(255, 249, 232); /* light green color */
}

.related-products-wrapper{
  display: grid;
  grid-auto-flow: column;
  overflow-x: scroll;
  scroll-behavior: smooth;
  gap: 0.75rem;
}