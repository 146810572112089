@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('styles/halloween.css');
@import url('styles/meme.css');
@import url('styles/gift.css');
@import url('styles/products.css');
@import url('styles/font.css');
@import url('styles/tooltip');
@import url('styles/fontawesome');
@import url('styles/pagination');
@import url('styles/overlay');

/* 고산자체 */
@font-face {
    font-family: 'Gosanja';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_20-10@1.0/Gosanja.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* 배민주아체 */
@font-face {
    font-family: 'BMJUA';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMJUA.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* 나눔스퀘어라운드 */
@font-face {
    font-family: 'NanumSquareRound';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* 배민을지로체 */
@font-face {
    font-family: 'BMEULJIRO';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.0/BMEULJIRO.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* cafe24 dongdong */
@font-face {
    font-family: 'Cafe24Dongdong';
    src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_twelve@1.1/Cafe24Dongdong.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Bareun_hipi */
@font-face {
    font-family: 'Bareun_hipi';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/naverfont_01@1.0/Bareun_hipi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

/* Pretendard-Regular */
@font-face {
    font-family: 'Pretendard-Regular';
    src: url('https://fastly.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

.nanum-square-round{
    font-family: 'NanumSquareRound', sans-serif;
}

.font-gosanja {
    font-family: 'Gosanja', sans-serif;
}

.font-bmjua {
    font-family: 'BMJUA', sans-serif;
}

.font-noto {
    font-family: "Noto Sans CJK KR", "Noto Sans KR", sans-serif;
}

.font-noto-kr {
    font-family: 'Noto Sans KR', sans-serif;
}

.font-bareun-hipi {
    font-family: 'Bareun_hipi', sans-serif;
}

.font-pretendard-regular {
    font-family: 'Pretendard-Regular', sans-serif;
}

.two-line{
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
     
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical
}

.custom-swiper-button-prev{
    position: absolute;
    top: 50%;
    left: 10px;
    right: auto;
}
.custom-swiper-button-next{
    position: absolute;
    top: 50%;
    right: 10px;
    left: auto;
}

.swiper-button-prev:after{
    font-family: swiper-icons !important;
    font-size: 18px !important;
    font-weight: 800;
    /* background-color: rgba(0,0,0,0.5) !important; */
    padding : 6px 10px 6px 8px !important;
    border-radius: 24px !important;
    color : rgba(0,0,0,0.5);
    line-height: 1 !important;
}

.swiper-button-next:after{
    font-family: swiper-icons !important;
    font-size: 18px !important;
    font-weight: 800;
    /* background-color: rgba(0,0,0,0.5) !important; */
    padding : 6px 8px 6px 10px !important;
    border-radius: 24px !important;
    color : rgba(0,0,0,0.5);
    line-height: 1 !important;
}

.custom-swiper {
    padding-bottom: 30px !important;
}

.padding-applied-swiper{
    padding-left: 24px !important;
}

.custom-swiper>.swiper-pagination>.swiper-pagination-bullet-active {
    background-color: #000;
}

.custom-swiper-pink {
    padding-top: 20px !important;
    padding-bottom: 30px !important;
}

.custom-swiper-pink>.swiper-pagination {
    bottom: 5px !important;
}

.custom-swiper-pink>.swiper-pagination>.swiper-pagination-bullet-active {
    background-color: #fc9696;
}

.custom-swiper-pink-white {
    padding-top: 20px !important;
    padding-bottom: 30px !important;
}

.custom-swiper-pink-white>.swiper-pagination {
    bottom: 5px !important;
}

.custom-swiper-pink-white>.swiper-pagination>.swiper-pagination-bullet {
    background-color: #fff;
    opacity: 0.35;
}

.custom-swiper-pink-white>.swiper-pagination>.swiper-pagination-bullet-active {
    background-color: #fc9696;
    opacity: 1;
}

iframe{
    max-width: 100%;
    width: 100%;
}


.progress-bar{
    margin: 0 auto;
    width:  40px;
    height: 2px;
    background-color: rgb(198, 198, 198);
}

.progress{
    width:  20px;
    height: 2px;
    background-color: #FFD41F;
}

/* 제목 요소 감춤 처리 */
.a11y-hidden {
    overflow: hidden;
    position: absolute;
    clip:     rect(0 0 0 0); /* IE 6,7 */
    clip:     rect(0,0,0,0);
    width:    1px;
    height:   1px;
    margin:   -1px;
    border:   0;
    padding:  0;
}

/* blind */
.blind-background-black::after {
    content: ' ';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
    border-radius: 1rem;
    background: -moz-linear-gradient(top,  rgba(0,0,0,1) 0%, rgba(0,0,0,1) 1%, rgba(0,0,0,0.76) 27%, rgba(0,0,0,0.4) 55%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 1%,rgba(0,0,0,0.76) 27%,rgba(0,0,0,0.4) 55%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(0,0,0,1) 0%,rgba(0,0,0,1) 1%,rgba(0,0,0,0.76) 27%,rgba(0,0,0,0.4) 55%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

/* fontawesome duotone red-white */
.duotone-red-white {
    --fa-primary-color: white;
    --fa-secondary-color: #FF6464;
    --fa-secondary-opacity: 1.0;
}

.forced-column{
    width: 100% !important;
    max-width: 100vw !important;
    display: inline-block !important;
}

.forced-column img, .forced-column p.se_textarea, div.se_sectionArea{
    width: 100% !important;
    max-width: 100vw !important;
}

.forced-column>.column {
    width: 100vw !important;
}
.forced-column>.column .goods_description {
    width: 100vw !important;
}
.forced-column>.column .goods_description>p {
    padding: 0 16px !important;
}

textarea::placeholder {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

#ch-plugin-launcher {
    opacity: 0.01;
}

.checkbox svg{
    width: 20px !important;
}

.hideScrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .hideScrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
}

.header-tab{
    width: 120px !important;
}

.header-tab>div:nth-child(2){
    left : 4% !important
}

.header-tab>div:nth-child(2)>div{
    margin: 4px 0 !important;
}

.list-btn-text-black>button{
    color: black !important;
    font-size: 14px !important;
}

.nohairline .hairline-b::after {
    height: 0px;
}

:is(.one, .two) {
    font-size: 2rem;
    margin: 1rem;
    animation: bubble 2s ease;
    animation-fill-mode: forwards;
    display: block;
    position: absolute;
    top: 0;
}
.one {
    opacity: 0;
    animation-delay: 1s;
}
.two {
    opacity: 0;
    animation-delay: 3.5s;
}
.three {
    animation: bubble2 2s ease;
    animation-fill-mode: forwards;
    opacity: 0;
    animation-delay: 5.5s;
}
.commingFour{
    opacity: 0;
    animation-delay: 6s;
}

.four{
    animation: bubble2 2s ease;
    color : white;
}

.five {
    animation: bubble2 4s ease;
    color : white;
}

.placeholder::-webkit-input-placeholder {
    color: #2C2C2C;
    font-size : 16px;
    font-weight: 500;
  }

.placeholder:-ms-input-placeholder {
    color: #2C2C2C;
    font-size : 16px;
    font-weight: 500;
}

.candy {
    opacity:0;
    animation: drop .4s linear forwards .8s;
  }

.send-candy{
    animation: send 1s ease
}
.send-candy2{
    animation: send2 1s ease
}

@keyframes send {
    0% {
        transform: translate(0, 0) rotate(20deg);
        opacity: 1;
    }
    20% {
        opacity: 0.6;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.3;
    }
    70% {
        opacity: 0.25;
    }
    80% {
        opacity: 0.1;
    }
    100% {
        transform: translate(10px, 200px) rotate(320deg);
        opacity: 0;
    }
}

@keyframes send2 {
    0% {
        transform: translate(0, 0) rotate(20deg);
        opacity: 1;
    }
    20% {
        opacity: 0.6;
    }
    40% {
        opacity: 0.4;
    }
    60% {
        opacity: 0.3;
    }
    70% {
        opacity: 0.25;
    }
    80% {
        opacity: 0.1;
    }
    100% {
        transform: translate(10px, 310px) rotate(320deg);
        opacity: 0;
    }
}

@keyframes bubble {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1
    }
    100% {
        opacity: 0;
        display: none;
    }
}
@keyframes bubble2 {
    from {
        opacity: 0;
    }
    to {
        opacity: 1
    }
}

.background-gradient-blue-purple {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#e9f2ff+29,ead3e8+100 */
    background: rgb(233,242,255); /* Old browsers */
    background: -moz-linear-gradient(top,  rgba(233,242,255,1) 29%, rgba(234,211,232,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  rgba(233,242,255,1) 29%,rgba(234,211,232,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  rgba(233,242,255,1) 29%,rgba(234,211,232,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e9f2ff', endColorstr='#ead3e8',GradientType=0 ); /* IE6-9 */
}

.background-gradient-black {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 43.23%);
}

.custom-mui-tab.Mui-selected {
    color: #FF6464 !important;
}

.custom-mui-tabs .MuiTabs-indicator {
    background-color: #FF6464 !important;
}

.custom-mui-tabs .MuiButtonBase-root {
    font-size: 0.98em !important;
}

.custom-mui-tabs {
    margin-top: 1vw !important;
}

.coupon-deco{
    width: 0;
    height: 0;
    border-top: 40px solid #FFF9DC;
    border-bottom: 40px solid transparent;
    border-right: 40px solid #FFF9DC;
    border-left: 40px solid transparent;
    border-top-right-radius: 10px;
}

.MuiAccordion-root {
    box-shadow: none ! important;
}

.MuiAccordion-root::before  { 
    opacity: 0.5;
}

.MuiAccordion-root.Mui-expanded:before {
    opacity: 0.5;
}

.MuiAccordion-root li {
    padding-bottom: 6px;
}

.bg-check-pattern {
    background-size: 10px, 10px, 10px 10px, 10px 10px;
    background-image: linear-gradient(90deg, #f4def948 0.3px, transparent 0.3px), linear-gradient(90deg, #f4def948 0.3px, transparent 0.3px), linear-gradient(#f4def948 0.3px, transparent 0.3px), linear-gradient(#f4def948 0.3px, transparent 0.3px);  
}

#smartEditorViewer ul { 
    list-style-type: disc; 
    list-style-position: inside; 
 }
 #smartEditorViewer ol { 
    list-style-type: decimal; 
    list-style-position: inside; 
 }
 #smartEditorViewer ul ul, #smartEditorViewer ol ul { 
    list-style-type: circle; 
    list-style-position: inside; 
    margin-left: 15px; 
 }
 #smartEditorViewer ol ol, #smartEditorViewer ul ol { 
    list-style-type: lower-latin; 
    list-style-position: inside; 
    margin-left: 15px; 
 }

.chess-bg {
    background: repeating-conic-gradient(#fff 0 90deg, #f0f0f0 0 180deg) 0 0/15px
        15px round;
}

.progress-bar {
    width: 100%;
    height: 30px;
    background-color: #eee;
    position: relative;
}

.progress-bar div {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #007bff;
}

.progress-label {
    margin-top: 10px;
}

.h-mobile-screen {
    height: calc(var(--vh, 1vh) * 100) !important;
}

.min-h-mobile-screen {
    min-height: calc(var(--vh, 1vh) * 100) !important;
}

.custom-pixie main > div > div > div:first-child>div.cropzone>div:first-child{
    border : 4px solid red;
    border-radius : 400px;
}

.pixie-wrapper .pixie-root {
    width: 100vw !important;
    height: 100vh !important;
}

@media (max-width: 768px) {
    .pixie-wrapper .pixie-root {
        width: 88vw !important;
        left: 50% !important;
        transform: translateX(-50%) !important;
    }
}

.prevent-longtab-select {
    user-select: none;
    -webkit-user-select: none;
    pointer-events: none;
}

.multiline-placeholder::placeholder {
    white-space: pre-line;  /* 줄바꿈 처리 */
}

/* 커스텀 스크롤바 */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
