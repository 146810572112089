.bottom-tail {
    display: inline-block;
    position: relative;
    margin: 0 auto 10px;
}

.bottom-tail:after {
    content: '';
    position: absolute;
    border-top: 10px solid #635348;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -9px;
    left : 50%;
    translate : (-50%, 0);
    
}

.left-bottom-tail {
    display: inline-block;
    position: relative;
    margin: 0 auto 10px;
}

.left-bottom-tail:after{
    content: '';
    position: absolute;
    border-top: 10px solid #635348;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -9px;
    left : 10%;
    /* translate : (100%, 0); */
}

.left-center-tail{
    display: inline-block;
    position: relative;
    margin: 0 auto 10px;
}

.left-center-tail::after{
    content: '';
    position: absolute;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-right: 10px solid #635348;
    border-left: 5px solid transparent;
    left : -15px;
    top : 40%;
}

.top-right-tail {
    position: relative;
    background-color: #E1E1E1;
    border-radius: 6px;   
    letter-spacing: -0.25px;
    margin-top: 6.8px;
    padding: 5px 22px 5px 11px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.top-right-tail:after {
    position: absolute;
    top: -10px;
    width : 0;
    z-index: 100;
    border-bottom: 10px solid #E1E1E1;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: '';
    right : 4%;
}

.top-right-tail-light-pink {
    position: relative;
    background-color: #FDF2F3;
    border-radius: 6px;   
    letter-spacing: -0.25px;
    margin-top: 6.8px;
    padding: 5px 22px 5px 11px;
    box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}

.top-right-tail-light-pink:after {
    position: absolute;
    top: -10px;
    width : 0;
    z-index: 100;
    border-bottom: 10px solid #FDF2F3;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    content: '';
    right : 4%;
}

.bottom-tail-right-light-pink {
    display: inline-block;
    position: relative;
    margin: 0 auto 10px;
}

.bottom-tail-right-light-pink:after {
    content: '';
    position: absolute;
    border-top: 10px solid #FDF2F3;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    bottom: -9px;
    right : 10%;
    translate : (-50%, 0);
    
}