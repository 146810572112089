
.card-wrapper,
.loading-wrapper {
  aspect-ratio: 1/1;
  position: relative;
  z-index: 10;
  min-width: 300px;
  max-width: calc(100vh - 44px);
  margin: auto;
}
  
.card-wrapper .background {
  position: absolute;
  z-index: -100;
  top: 0;
  object-fit: cover;
}

/* ----------------------------------- bat ---------------------------------- */
.card-wrapper .bat1 {
  position: absolute;
  width: 18.6%;
  /* min-width: 56px; */
  top: 10%;
  right: 14.2%;
}

.card-wrapper .bat2 {
  position: absolute;
  width: 16%;
  /* min-width: 50px; */
  top: 37%;
  left: 9%;
}

.card-wrapper .bat3 {
  position: absolute;
  width: 12%;
  /* min-width: 37px; */
  top: 51%;
  left: 38%;
}

/* --------------------------------- pumpkin -------------------------------- */
.card-wrapper .pumpkin-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* width: 49%;
  bottom: 2%;
  left: 50%;
  transform: translateX(-50%);
  padding: 0; */
  z-index: 500;
}

.card-wrapper .pumpkin-wrapper .pumpkin-main {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.card-wrapper .pumpkin-wrapper .pumpkin-click {
  position: absolute;
  width: 50%;
  height: 33%;
  border-radius: 20%;
  bottom: 7%;
  left: 25%;
  z-index: 1;
}

.card-wrapper .click-pumpkin-text {
  position: absolute;
  color: #C0C0C0;
  font-size: 20px;
  white-space: pre-line;
  text-align: center;
  width: 100%;
  top: 36%;
}

/* ---------------------------------- body ---------------------------------- */
.card-wrapper .ticon-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  /* bottom: 10%; */
  z-index: 400;
  /* background: ivory; */
}

.card-wrapper .face {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 400;
  top: 2%;
  /* background: rgba(24, 73, 188, 0.414); */
}
.card-wrapper .body {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

/* ---------------------------------- star ---------------------------------- */
.card-wrapper .star {
  position: absolute;
  aspect-ratio: 1/1;
}

.card-wrapper .star-1 {
  top: 55%;
  right: 30%;
  width: 5%;
}
.card-wrapper .star-2 {
  top: 50%;
  left: 30%;
  width: 5.5%;
}
.card-wrapper .star-3 {
  top: 40%;
  right: 35%;
  width: 6%;
}
.card-wrapper .star-4 {
  top: 40%;
  left: 30%;
  width: 5.5%;
}
.card-wrapper .star-5 {
  top: 30%;
  right: 30%;
  width: 5.5%;
}

.card-wrapper .star-shape {
  width: 100%;
  height: 100%;
}

/* ---------------------------- background assets --------------------------- */
.card-wrapper .ghost {
  width: 18%;
  position: absolute;
  right: 3%;
  bottom: 21%;
}

.card-wrapper .wood {
  width: 24%;
  position: absolute;
  bottom: 10%;
  left: 3px;
}

/* --------------------------------- effect --------------------------------- */
.card-wrapper .effect-wrapper {
  position: absolute;
  width: 66.8%;
  height: 31.2%;
  top: 30%;
  left: 50%;
}

/* confetti */
.card-wrapper .effect-confetti {
  position: absolute;
  object-fit: cover;
  left: 2%;
  width: 102%;
}

/* candy */
.card-wrapper .effect-candy {
  width: 18%;
  position: absolute;
}

.card-wrapper .candy-1 {
  left: 12%;
}
.card-wrapper .candy-2 {
  right: 7.6%;
}

.card-wrapper .candy-3 {
  top: 42%;
}

.card-wrapper .candy-4 {
  top: 39.3%;
  right: 0;
}

/* light */
.card-wrapper .effect-light {
  position: absolute;
  bottom: 0;
}

/* --------------------------------- loading -------------------------------- */
.loading-wrapper {
  padding-top: 20px;
}

.loading-wrapper .text {
  color: #C0C0C0;
  font-weight: 500;
  text-align: center;

}

.loading-wrapper .loading-ghost {
  width: 20%;
  margin: 30px auto;
}